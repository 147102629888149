import * as React from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
    
import "../styles/fontawesome-free-5.15.4-web/css/all.css"

    
export default ()  => (
    <>
{/*<Navbar></Navbar>*/}

    
<Seo />


<div class=" w-screen ">   
    <div class="mx-auto max-w-7xl">
        <div class="max-w-4xl mx-auto px-5 mt-16">
<div class="text-center">
    <h2 class="font-semibold text-3xl">Book Your Motoryacht Charter Today!</h2>
    <p class="max-w-md mx-auto mt-2 text-gray-500">Don't miss the chance to embark on an extraordinary journey through the pristine waters of Marbella. Whether you're a seasoned yachtsman or new to yachting, our experienced team will ensure your voyage is seamless, safe, and unforgettable. Contact us today to reserve your motoryacht charter and prepare for a dreamlike adventure on the Mediterranean Sea!</p>
    <div class ="pt-4">
        <a  href="https://candelamarbella.com/en/sail-away/"><button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
        Book now
        </button></a>
    </div>
</div>


<div class="grid md:grid-cols-2 gap-10 mt-10">
    <div class="flex gap-4 items-start">
        <span class="text-violet-600 bg-violet-500/10 p-3 rounded-full">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"><path d="M7.28856 0.796908C7.42258 0.734364 7.57742 0.734364 7.71144 0.796908L13.7114 3.59691C13.8875 3.67906 14 3.85574 14 4.05V10.95C14 11.1443 13.8875 11.3209 13.7114 11.4031L7.71144 14.2031C7.57742 14.2656 7.42258 14.2656 7.28856 14.2031L1.28856 11.4031C1.11252 11.3209 1 11.1443 1 10.95V4.05C1 3.85574 1.11252 3.67906 1.28856 3.59691L7.28856 0.796908ZM2 4.80578L7 6.93078V12.9649L2 10.6316V4.80578ZM8 12.9649L13 10.6316V4.80578L8 6.93078V12.9649ZM7.5 6.05672L12.2719 4.02866L7.5 1.80176L2.72809 4.02866L7.5 6.05672Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></span>
        <div>
            <h3 class="font-semibold text-xl">Professional Crew</h3>
            <p class="mt-1 text-gray-500">Our experienced and attentive crew members are dedicated to providing exceptional service throughout your journey. From expert captains to skilled chefs and hospitable staff, we strive to exceed your expectations and make your time on board truly memorable.</p>
        </div>
    </div>


    <div class="flex gap-4 items-start">
        <span class="text-violet-600 bg-violet-500/10 p-3 rounded-full">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"><path d="M7.28856 0.796908C7.42258 0.734364 7.57742 0.734364 7.71144 0.796908L13.7114 3.59691C13.8875 3.67906 14 3.85574 14 4.05V10.95C14 11.1443 13.8875 11.3209 13.7114 11.4031L7.71144 14.2031C7.57742 14.2656 7.42258 14.2656 7.28856 14.2031L1.28856 11.4031C1.11252 11.3209 1 11.1443 1 10.95V4.05C1 3.85574 1.11252 3.67906 1.28856 3.59691L7.28856 0.796908ZM2 4.80578L7 6.93078V12.9649L2 10.6316V4.80578ZM8 12.9649L13 10.6316V4.80578L8 6.93078V12.9649ZM7.5 6.05672L12.2719 4.02866L7.5 1.80176L2.72809 4.02866L7.5 6.05672Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></span>
        <div>
            <h3 class="font-semibold text-xl">Breathtaking Destinations</h3>
            <p class="mt-1 text-gray-500">Explore the picturesque coastlines, hidden coves, and exclusive islands of Marbella and the surrounding areas. Whether you wish to visit the charming Puerto Banus, the tranquil Gibraltar Strait, or the vibrant Malaga, our yacht charters take you to the most desirable destinations in the region </p>
        </div>
    </div>


    <div class="flex gap-4 items-start">
        <span class="text-violet-600 bg-violet-500/10 p-3 rounded-full">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"><path d="M7.28856 0.796908C7.42258 0.734364 7.57742 0.734364 7.71144 0.796908L13.7114 3.59691C13.8875 3.67906 14 3.85574 14 4.05V10.95C14 11.1443 13.8875 11.3209 13.7114 11.4031L7.71144 14.2031C7.57742 14.2656 7.42258 14.2656 7.28856 14.2031L1.28856 11.4031C1.11252 11.3209 1 11.1443 1 10.95V4.05C1 3.85574 1.11252 3.67906 1.28856 3.59691L7.28856 0.796908ZM2 4.80578L7 6.93078V12.9649L2 10.6316V4.80578ZM8 12.9649L13 10.6316V4.80578L8 6.93078V12.9649ZM7.5 6.05672L12.2719 4.02866L7.5 1.80176L2.72809 4.02866L7.5 6.05672Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></span>
        <div>
            <h3 class="font-semibold text-xl">Customizable Itineraries</h3>
            <p class="mt-1 text-gray-500">Tailor your charter experience to suit your preferences. Whether you desire a day of thrilling water sports, a relaxing sunbathing session on the deck, or a gourmet dining experience under the stars, our team will create a bespoke itinerary to meet your desires.</p>
        </div>
    </div>


    <div class="flex gap-4 items-start">
        <span class="text-violet-600 bg-violet-500/10 p-3 rounded-full">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"><path d="M7.28856 0.796908C7.42258 0.734364 7.57742 0.734364 7.71144 0.796908L13.7114 3.59691C13.8875 3.67906 14 3.85574 14 4.05V10.95C14 11.1443 13.8875 11.3209 13.7114 11.4031L7.71144 14.2031C7.57742 14.2656 7.42258 14.2656 7.28856 14.2031L1.28856 11.4031C1.11252 11.3209 1 11.1443 1 10.95V4.05C1 3.85574 1.11252 3.67906 1.28856 3.59691L7.28856 0.796908ZM2 4.80578L7 6.93078V12.9649L2 10.6316V4.80578ZM8 12.9649L13 10.6316V4.80578L8 6.93078V12.9649ZM7.5 6.05672L12.2719 4.02866L7.5 1.80176L2.72809 4.02866L7.5 6.05672Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></span>
        <div>
            <h3 class="font-semibold text-xl">Luxurious Amenities</h3>
            <p class="mt-1 text-gray-500">Indulge in the lap of luxury with our onboard amenities. From spacious lounges and well-appointed cabins to Jacuzzis and state-of-the-art entertainment systems, our yachts are equipped with all the comforts you could ever wish for. </p>
        </div>
    </div>


    <div class="flex gap-4 items-start">
        <span class="text-violet-600 bg-violet-500/10 p-3 rounded-full">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"><path d="M7.28856 0.796908C7.42258 0.734364 7.57742 0.734364 7.71144 0.796908L13.7114 3.59691C13.8875 3.67906 14 3.85574 14 4.05V10.95C14 11.1443 13.8875 11.3209 13.7114 11.4031L7.71144 14.2031C7.57742 14.2656 7.42258 14.2656 7.28856 14.2031L1.28856 11.4031C1.11252 11.3209 1 11.1443 1 10.95V4.05C1 3.85574 1.11252 3.67906 1.28856 3.59691L7.28856 0.796908ZM2 4.80578L7 6.93078V12.9649L2 10.6316V4.80578ZM8 12.9649L13 10.6316V4.80578L8 6.93078V12.9649ZM7.5 6.05672L12.2719 4.02866L7.5 1.80176L2.72809 4.02866L7.5 6.05672Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></span>
        <div>
            <h3 class="font-semibold text-xl">Seamless Booking Process</h3>
            <p class="mt-1 text-gray-500">Our user-friendly website allows for easy and secure online bookings. Whether you want a short-term day charter or an extended vacation, we offer flexible rental options to accommodate your schedule. </p>
        </div>
    </div>


    <div class="flex gap-4 items-start">
        <span class="text-violet-600 bg-violet-500/10 p-3 rounded-full">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"><path d="M7.28856 0.796908C7.42258 0.734364 7.57742 0.734364 7.71144 0.796908L13.7114 3.59691C13.8875 3.67906 14 3.85574 14 4.05V10.95C14 11.1443 13.8875 11.3209 13.7114 11.4031L7.71144 14.2031C7.57742 14.2656 7.42258 14.2656 7.28856 14.2031L1.28856 11.4031C1.11252 11.3209 1 11.1443 1 10.95V4.05C1 3.85574 1.11252 3.67906 1.28856 3.59691L7.28856 0.796908ZM2 4.80578L7 6.93078V12.9649L2 10.6316V4.80578ZM8 12.9649L13 10.6316V4.80578L8 6.93078V12.9649ZM7.5 6.05672L12.2719 4.02866L7.5 1.80176L2.72809 4.02866L7.5 6.05672Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></span>
        <div>
            <h3 class="font-semibold text-xl">Committed to Sustainability</h3>
            <p class="mt-1 text-gray-500">We are dedicated to preserving the stunning natural environment we operate in. Our motoryacht charters are designed to minimize our environmental impact while providing you with an exceptional experience.</p>
        </div>
    </div>
</div>

</div>

</div>

</div>
     

    <Footer></Footer>
    </>
    
)

